import { Component, Input, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-model',
  templateUrl: './model.component.html',
  styleUrls: ['./model.component.scss']
})
export class ModelComponent implements OnInit {

  content: any;
  //modalRef?: BsModalRef | null;

  constructor(private modalService: BsModalService) { }

  ngOnInit(): void {

  }

  close(){
    this.modalService.hide();
  }

}
