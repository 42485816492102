<div class="service-section">
    <div class="container">
        <div class="row">

            <div class="col-12 p-0">
                <div class="header-box">
                    <h2> Services </h2>
                </div>
            </div>
            
            <div class="col-12 p-0">               
                <div class="d-flex flex-wrap box-list align-items-start w-100">
                    <div class="col-lg-4 col-sm-6 col-12">
                        <a href="/service" class="service-box">
                            <div class="img-box">
                                <figure>
                                    <img src="assets/images/150202.png" alt="service-img" >
                                </figure>
                            </div>
                            <div class="text-box">
                                <h5>site identification and feasibility</h5>
                                <p>One of the most important aspects of a clinical research is selecting optimal Clinical Study Site. The ability to identify, engage and effectively 
                                    work with experienced staff...  <!--<a> see more </a>-->  </p>
                            </div>
                        </a>
                    </div>
                    <div class="col-lg-4 col-sm-6 col-12">
                        <a href="/service" class="service-box">
                            <div class="img-box">
                                <figure>
                                    <img src="assets/images/Ethics Committee Registration.png" alt="service-img" >
                                </figure>
                            </div>
                            <div class="text-box">
                                <h5>Ethics Committee Registration</h5>
                                <p>For conduct of clinical trials, Ethics committee plays a vital role for review and approve clinical trial for safeguarding rights, safety and 
                                    well-being of... <!--<a> see more </a>-->
                                </p>
                            </div>
                        </a>
                    </div>
                    <div class="col-lg-4 col-sm-6 col-12">
                        <a href="/service" class="service-box">
                            <div class="img-box">
                                <figure>
                                    <img src="assets/images/Patient Safety Management.png" alt="service-img" >
                                </figure>
                            </div>
                            <div class="text-box">
                                <h5>Patient Safety Management</h5>
                                <p>Monitoring and management of patient safety during clinical trial is a critical component throughout the drug development life cycle.
                                    At Skycraper, Our experience... <!--<a> see more </a>--></p>
                            </div>
                        </a>
                    </div> 
                    <div class="col-12">
                        <button type="button" (click)="redirect()" class="btn">See More</button>
                    </div> 
                </div>
            </div>
        </div>
    </div>
</div>