// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  appVersion: 'v726demo1',
  USERDATA_KEY: 'authf649fc9a5f55',
  isMockEnabled: true,
  // baseURL: 'https://
  baseURL: 'https://skycrapercrs.in/skycrapercrs/api/',
  baseAuthURL: 'http://localhost:3001/api/v1/', 
  baseAclURL: 'http://localhost:3001/api/v1/',
  baseCmsURL: 'http://localhost:3001/api/v1/',
  baseSettingURL: 'http://localhost:3001/api/v1/',
  imageURL: 'http://localhost:3003/public/upload/',
  excelURL: 'http://localhost:3001/public/upload/excel/',
  siteKey: '6LcnU0kgAAAAAD72UfSii97juuPAodvlMwB50vKA',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
