import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PhotoService } from 'src/app/service/photo.service';
import Swiper from 'swiper';

@Component({
  selector: 'app-event-details',
  templateUrl: './event-details.component.html',
  styleUrls: ['./event-details.component.scss']
})
export class EventDetailsComponent implements OnInit {

  

  swiperConfig = {

    slidesPerView:3,
    spaceBetween:30,
    slidesPerGroup:3,
    loop:true,
    autoplay:{
        delay: 2300,
        disableOnInteraction: false
      },
    loopFillGroupWithBlank:true,
    pagination:{
      clickable: true
    },
    class:"mySwiper",
    breakpoints: {
      0: {
      spaceBetween: 10,
      slidesPerView:1,
      },
      576: {
      spaceBetween: 20,
      slidesPerView:2,
      },
      992: {
          spaceBetween: 30,
          slidesPerView:3,
      }
    }
}

images: string[] = [];
albums:any= [];
albumName: string;
album: any;
nameAlbum:any;

  constructor(private http: HttpClient,private PhotoService:PhotoService , private route: ActivatedRoute) {}

  ngOnInit():void {
    
    this.albumName = this.route.snapshot.paramMap.get('albumName');
    
    // this.PhotoService.getAlbums().subscribe(albums => {
    //   this.albums = albums;
    //   // this.album = this.albums.find(a => a.name === this.albumName);
    // });

    this.image()
  }

 
 image(){
  this.PhotoService.getAlbums().subscribe(data => {
    this.albums = data.albums;
    
  });
}
}

