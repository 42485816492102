<div class="expertise-section">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="header-box">
          <h2>Expertise</h2>
        </div>
      </div>

      <div class="col-12">
        <div class="accordion-list-box">
          <accordion [isAnimated]="true">
            <accordion-group
              heading="Why Is Skycraper Required In The First PlaceE"
            >
              <div>
                <ul class="expertise-list">
                  <li>
                    Due limited knowledge and access to clinical research sites
                    it becomes challenging for the Pharmaceutical Companies and
                    CRO's to conduct a proposed clinical trial.
                  </li>
                  <li>
                    Whereas SkyCraper's work close with the research sites so
                    they have deep information about sites including its
                    strength and weaknesses which becomes valuable information
                    for the Pharmaceutical Companies and CRO's in understanding
                    the feasibility of site for successfully conducting clinical
                    trial.
                  </li>
                  <li>
                    Proper site management leads to speeding up the research
                    process.
                  </li>
                  <ul class="expertise-list inner-list">
                    <li>Successful subject recruitment and retention.</li>
                    <li>
                      Dedicated, well trained (protocol and ICH GCP) and
                      experienced site team for project management.
                    </li>
                    <li>
                      Site Initiation, Site Development, Interim monitoring,
                      close out visits, Patient Compliance etc.
                    </li>
                  </ul>
                </ul>
              </div>
            </accordion-group>

            <accordion-group
              heading="What Are The Benefits Of Having A Skycraper"
            >
              <div>
                <ul class="expertise-list">
                  <li>
                    Efficient execution of operational activities & accurate and
                    consistent documentation.
                  </li>
                  <li>
                    Due close interaction with the sites we establish a good
                    relationship with the investigator and other site staff
                    which in return may help in easy access to deep seated
                    information eventually reduces the study timeline.
                  </li>
                  <li>
                    Can speed up patient recruitment process as per the trial
                    protocol.
                  </li>
                  <li>
                    We can also help in generation of high-quality data as well
                    as its tracking and management.
                  </li>
                  <li>The all helps in making the study cost effective.</li>
                </ul>
              </div>
            </accordion-group>

            <accordion-group heading="Confidentiality">
              <div>
                <ul class="expertise-list">
                  <li>
                    Privacy of patients and confidentiality of clinical trial
                    information are very important.
                  </li>
                  <li>
                    At SkyCraper, we provide below-mentioned ways to maintain
                    patient confidentiality:
                  </li>
                  <ul class="expertise-list inner-list">
                    <li>
                      Create thorough policies and confidentiality agreements.
                    </li>
                    <li>Provide regular training.</li>
                    <li>
                      Make sure all information is stored on secure systems.
                    </li>
                    <li>Maintaining the security of written records.</li>
                  </ul>
                </ul>
              </div>
            </accordion-group>

            <accordion-group heading="Data Accuracy">
              <div>
                <ul class="expertise-list">
                  <li>
                    Gathering high-quality, reliable, and statistically, sound
                    data is the goal for every clinical trial, and effective
                    data management is essential to ensuring accurate data
                    collection, entry, reports, and validation.
                  </li>
                  <li>
                    At SkyCraper, we provide below-mentioned ways to collect the
                    data accurately:
                  </li>
                  <ul class="expertise-list inner-list">
                    <li>
                      Capture only the data that are relevant the study protocol
                      and do not deviate from the study protocol unless it is
                      necessary.
                    </li>
                    <li>
                      The data collection method should be standardized and all
                      study coordinators should collect data using the same
                      technique, forms, and format.
                    </li>
                    <li>
                      Well organized and maintained data capture makes data
                      entry and analysis easy.
                    </li>
                  </ul>
                </ul>
              </div>
            </accordion-group>

            <accordion-group heading="Patient Satisfaction">
              <div>
                <ul class="expertise-list">
                  <li>
                    Patient satisfaction is a basic and widely used indicator
                    for the measurement of the quality in clinical trials.
                  </li>
                  <li>
                    At SkyCraper, Our experience research staffs provide all
                    essential services to the patients.
                  </li>
                </ul>
              </div>
            </accordion-group>

            <accordion-group heading="Patient Counseling">
              <div>
                <ul class="expertise-list">
                  <li>
                    Patient counselling is a process through which health care
                    councillor attempt to increase patient knowledge of
                    health-related issues. Patient counselling procedure may be
                    verbal or written performed on an individual basis or in
                    groups, & provide directly to the patient.
                  </li>
                  <li>
                    At SkyCraper, we provide below-mentioned ways for patient
                    counselling:
                  </li>
                  <ul class="expertise-list inner-list">
                    <li>Use language that the patient understands.</li>
                    <li>
                      Present facts and concepts in simple words and in a
                      logical order. Use open-ended questions.
                    </li>
                    <li>
                      Verify the patient's understanding by means of feedback.
                    </li>
                    <li>
                      Give an opportunity to the trial patient to raise all
                      their doubts.
                    </li>
                    <li>Help the patient to plan the follow up visit.</li>
                  </ul>
                </ul>
              </div>
            </accordion-group>

            <accordion-group
              heading="Accommodating Them With Meal, Water And Other Basic Requirements Timely Follow Up Within Timeline"
            >
              <div>
                <ul class="expertise-list">
                  <li>
                    Long-term distance from the clinical site, long and early
                    visits are stressful for the patient and often become the
                    reason for not only untimely leave of clinical trial but
                    also an obstacle for taking participation in the clinical
                    trial at all.
                  </li>
                  <li>
                    At SkyCraper provide the service of patients’ accommodation
                    at the hospital. Striking the balance between expenses
                    optimization for a client and the quality of provided
                    services, we do not lose sight of important principles such
                    as:
                  </li>
                  <ul class="expertise-list inner-list">
                    <li>
                      Comfortable rooms with reserved facilities and the
                      possibility of luggage storage.
                    </li>
                    <li>
                      Special meals (diet) for the patients as per the study
                      requirements.
                    </li>
                    <li>
                      Also, provide water and other basic requirements for the
                      patients.
                    </li>
                    <li>
                      Reminder call to the patient for the next visit/upcoming
                      visit by train staff.
                    </li>
                  </ul>
                </ul>
              </div>
            </accordion-group>

            <accordion-group
              heading="New Normal - Home-base Visits As Per Requirement"
            >
              <div>
                <ul class="expertise-list">
                  <li>
                    SkyCraper, Our experience research staffs provide patients
                    with the same high quality, expert service they receive from
                    the clinical trial site, just in the comfort of their home.
                  </li>
                  <li>
                    SkyCraper, Our experience research staff contacts the
                    patient directly to schedule appointments and then go to the
                    patient's home, provide treatment, care, and relevant tests,
                    document any findings, then ship any specimens to the
                    designated laboratory.
                  </li>
                </ul>
              </div>
            </accordion-group>

            <accordion-group heading="Site Staff Traning">
              <div>
                <ul class="expertise-list">
                  <li>
                    The good conduct of a clinical trial depends on how
                    efficiently the site staff has been trained, in order to
                    achieve the desired results in compliance with GCP. Clinical
                    research related trainings for site staff is continuous
                    process. For swift conduct of study it is necessary that the
                    research staff is efficiently trained on the protocol
                    procedures, SOPs
                  </li>
                  <ul class="expertise-list inner-list">
                    For example;
                    <li>
                      SkyCraper provide well trained, experienced, dedicated and
                      full time clinical research coordinators and other site
                      staff requirements.
                    </li>
                    <li>
                      SkyCraper provide the continues training to the site
                      staff.
                    </li>
                  </ul>
                </ul>
              </div>
            </accordion-group>
          </accordion>
        </div>
      </div>
    </div>
  </div>
</div>
