import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EventRoutingModule } from './event-routing.module';
import { EventComponent } from './event.component';
import { SwiperModule } from "swiper/angular";
import { SharedModule } from 'src/app/shared/shared/shared.module';
import { EventDetailsComponent } from './event-details/event-details.component';


@NgModule({
  declarations: [EventComponent , EventDetailsComponent],
  imports: [
    CommonModule,
    EventRoutingModule,
    SwiperModule,
    SharedModule
  ]
})
export class EventModule { }
