import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContactusComponent } from 'src/app/component/contactus/contactus.component';
import { AboutusComponent } from 'src/app/component/aboutus/aboutus.component';
import { ServiceComponent } from 'src/app/component/service/service.component';
import { ExpertiseComponent } from 'src/app/component/expertise/expertise.component';
import { TherapeuticComponent } from 'src/app/component/therapeutic/therapeutic.component';
import { BannerComponent } from 'src/app/component/banner/banner.component';
import { GalleryComponent } from 'src/app/component/gallery/gallery.component';
import {AccordionModule} from "ngx-bootstrap/accordion";
import { FormsModule, ReactiveFormsModule } from '@angular/forms'

// import { SelectDropDownModule } from 'ngx-select-dropdown'
//import { NgxUsefulSwiperModule } from 'ngx-useful-swiper';



@NgModule({
  declarations: [
    ContactusComponent,
    AboutusComponent,
    ServiceComponent,
    ExpertiseComponent,
    TherapeuticComponent,
    BannerComponent,
    GalleryComponent
  ],
  exports:[
    ContactusComponent,
    AboutusComponent,
    ServiceComponent,
    ExpertiseComponent,
    TherapeuticComponent,
    BannerComponent,
    GalleryComponent
 ],
  imports: [
    CommonModule,
     AccordionModule.forRoot(),
    // SelectDropDownModule, 
   //NgxUsefulSwiperModule,
   FormsModule, ReactiveFormsModule
  ]
})
export class SharedModule { }
