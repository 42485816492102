<div class="modal-header">
  <h4 class="modal-title pull-left">{{ content.name }}</h4>
  <button type="button" class="btn-close close pull-right" aria-label="Close" (click)="close()" >
    <span aria-hidden="true" class="visually-hidden">&times;</span>
  </button>
</div>
<div class="modal-body">
  <p>{{ content.content }}</p>
  <p>{{content.content2}}</p>
  <ul class="ul-list" > 
      <li *ngFor="let list of content.list">{{list}}</li>
  </ul>
</div>
