import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './layout/header/header.component';
import { FooterComponent } from './layout/footer/footer.component';
import { NgxUsefulSwiperModule } from 'ngx-useful-swiper';
import { SwiperModule } from "swiper/angular";
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ModelComponent } from './component/model/model.component';
import { EventModule } from './pages/event/event.module';

// import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
// import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
// import { SelectDropDownModule } from 'ngx-select-dropdown'
// import { NgxPageScrollModule } from 'ngx-page-scroll';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    ModelComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    SwiperModule,
    NgxUsefulSwiperModule,
    BrowserAnimationsModule,
    HttpClientModule,
    EventModule
    
    // BrowserAnimationsModule,
    // BsDropdownModule.forRoot(),
    // SelectDropDownModule,
    // NgxPageScrollModule,
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
