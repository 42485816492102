import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { catchError, map } from "rxjs/operators";
import { Observable, of } from "rxjs";
import { environment } from "src/environments/environment";
@Injectable({
  providedIn: 'root'
})
export class ServiceService {
  baseUrl="https://skycrapercrs.in/skycrapercrs/api/";

  constructor(public http: HttpClient) { }
  news_searchAll(moduleNameServiceRoute, searchObj?) {
  
    return this.http
      .post(this.baseUrl + "/search", {
      })
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((err) => {
          return null;
        })
      );
  }

  create(resource): Observable<any> {
    return this.http
      .post(this.baseUrl + "rights" + "/", resource)
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((err) => {
          return err;
        })
      );
  }
  contect_create(resource): Observable<any> {
    return this.http
      .post(this.baseUrl + "contact/" + "add", resource)
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((err) => {
          return err;
        })
      );
  }
}
