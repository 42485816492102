<div class="contact-section">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="header-box">
                    <h2> contact us </h2>
                </div>
            </div>
            
            <div class="col-12">
                <div class="contact-box">
                    <form class="form-box" [formGroup]="menuForm">
                        <ul class="input-list" >
                            <li> 
                                <div class="form-grp col-grp">
                                    <input type="text" placeholder="First name" formControlName="name">
                                    <mat-error *ngIf="isControlHasError('name', 'required')">
                                        Name is required
                                      </mat-error>
                                      <mat-error *ngIf="isControlHasError('name', 'pattern')">
                                        Name is not valid
                                      </mat-error>
                                </div>
                                <div class="form-grp col-grp">
                                    <input type="text" placeholder="Email" formControlName="email">
                                    <mat-error *ngIf="isControlHasError('email', 'required')">
                                        Email is required
                                      </mat-error>
                                      <mat-error *ngIf="isControlHasError('email', 'pattern')">
                                        Email name is not valid
                                      </mat-error>
                                </div>
                            </li>
                            <li> 
                                <div class="form-grp">
                                    <input type="text" placeholder="Subject" formControlName="subject">
                                    <mat-error *ngIf="isControlHasError('subject', 'required')">
                                        Subject name is required
                                      </mat-error>
                                      <mat-error *ngIf="isControlHasError('subject', 'pattern')">
                                        Subject name is not valid
                                      </mat-error>
                                </div>
                            </li>
                            <li> 
                                <div class="form-grp">
                                    <textarea class="text-area" placeholder="Message" formControlName="message"></textarea>
                                    <mat-error *ngIf="isControlHasError('message', 'required')">
                                        Message is required
                                      </mat-error>
                                      <mat-error *ngIf="isControlHasError('message', 'pattern')">
                                        Message is not valid
                                      </mat-error>
                                </div>
                            </li>
                        </ul>
                        <button type="button" class="link-btn" (click)="save()"
                        [disabled]="isSubmitted ? true : false">Submit</button>
                    </form>
                </div>
            </div>
            <div class="col-md-6">
                <!-- <div class="map-box">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3655.667367526901!2d72.3885488291476!3d23.616259550738828!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4
                    f13.1!3m3!1m2!1s0x395c43c79f0fdff7%3A0x6b1f6e82399e6d55!2sSkyCraper%20Clinical%20Research%20Solutions%20Private%20Limited!5e0!3m2!1sen!2sin!4v1673145800149!5m2!
                    1sen!2sin" width="100%" height="100%" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade">
                    </iframe>
                </div> -->
                <!-- <div class="news-box">
                    <h5>Latest News</h5>
                    <ul class="news-list" >
                        <li>hasncjnjsncjs j sdjcsdjcsdj jcsdjcsdjc sdcsjdcds csd csdj</li>
                        <li>h</li>
                        <li>h</li>
                        <li>hasncjnjsncjs j sdjcsdjcsdj jcsdjcsdjc sdcsjdcds csd csdj</li>
                        <li>h</li>
                        <li>h</li>
                        <li>hasncjnjsncjs j sdjcsdjcsdj jcsdjcsdjc sdcsjdcds csd csdj</li>
                        <li>h</li>
                        <li>h</li>
                    </ul>
                </div> -->
            </div>
        </div>
    </div>
</div>