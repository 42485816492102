<div class="banner-section" [ngClass]="componentClass" >

    <div class="container">
        <div class="row">
            <div  *ngIf="temp == 'home'" > 
                <!-- home -->
                <div class="col-lg-6 col-md-8 col-12">
                    <div class="left-box" >
                        <span>Towards Humanity</span>
                        <div class="inner-text">
                            <h1>Wel come to <b>
                                SkyCraper</b></h1>
                            <p>SkyCraper CRS is a dedicated organization built to accelerate patient and client satisfaction. Diversification of clinical trials by SkyCraper have been managing well since 2017. Drug Development Trial, New Molecular Entity Trial, Phase Trial, Bioequivalence Studies and Pharmacokinetic-Pharmacodynamic Studies. </p>
                        </div>
                        <!-- <div class="btn-box">
                            <button type="button" class="btn">contact Us</button>
                        </div> -->
                    </div>
                </div>

            </div>
                <!-- other -->
                <div class="col-12">
                    <div class="left-box center-box">
                        <!-- <span>SHINE YOUR SMILE WITH US</span> -->
                        <div class="inner-text">
                            <h1>{{data}}</h1>
                        </div>
                        <!-- <div class="btn-box">
                            <button type="button" class="btn">contact Us</button>
                        </div> -->
                    </div>
                </div>
        </div>
    </div>

</div>