<div class="about-section">
    <div class="container">
        <div class="row">

            <div class="d-flex flex-wrap align-items-start w-100">
                <div class="col-12">
                    <div class="header-box">
                        <h2> about us </h2>
                    </div>
                </div>
                
                <div class="col-12 col-padding d-flex">
                    <div class="about-img" >
                        <figure>
                            <img src="assets/images/SC18.jpg">
                        </figure>
                    </div>
                    <div class="about-text">
                        <h3>Our Responsibility</h3>
                        
                        <ul class="about-list">
                            <li> Efficient execution of operational activities & accurate and consistent documentation.</li>
                            <li>Due close interaction with the sites we establish a good relationship with the investigator and 
                                other site staff which in return may help in easy access to deep seated information eventually reduces the study timeline.</li>
                             <li>Can speed up patient recruitment process as per the trial protocol. </li>
                             <li>We can also help in generation of high-quality data as well as its tracking and management. </li>
                              <li>The all helps in making the study cost effective. </li>
                        </ul>

                        <!-- <div class="inner-box">
                            <p> SkyCraper is a Site Management Organization, which has remained committed to providing quality and client-driven services. We share the same goals as 
                                our clients and will continue to provide the very highest level of professional services to deliver true value to them with Quality and Transparency. 
                            </p>
    
                            <p>
                                SkyCraper acts as a common platform between Principal Investigator and Study Sponsor /CROs for successful execution of Clinical Trials. It has been established 
                                to meet the ever-growing performance and quality expectations at the level of clinical trial services.
                            </p>
    
                            <p>
                                SkyCraper is an Organization of excellence, which provides a step ahead towards brighter future for the upcoming clinical research in India. SkyCraper 
                                is the fastest growing research coordinating agency in India
                            </p>
                        </div> -->

                        <div class="btn-box">
                            <button type="button"  (click)="redirect()" class="btn">Know More</button>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>