import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Logs } from 'selenium-webdriver';
import { ServiceService } from 'src/app/service/service.service';
@Component({
  selector: 'app-contactus',
  templateUrl: './contactus.component.html',
  styleUrls: ['./contactus.component.scss']
})
export class ContactusComponent implements OnInit {
  menuForm: FormGroup;
  isSubmitted: boolean;
  config = {
    displayFn:(item: any) => { return item.hello.world; } ,
    displayKey:"description",
    search:true ,
    height: 'auto',
    placeholder:'Select' ,
    customComparator: ()=>{} ,
    limitTo: 0 ,
    moreText: 'more' ,
    noResultsFound: 'No results found!' ,
    searchPlaceholder:'Search' ,
    searchOnKey: 'name',
    clearOnSelection: false,
    inputDirection: 'ltr' ,
    selectAllLabel: 'Select all' ,
    enableSelectAll: false,
    Option:["a","b"],
  }

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private ServiceService: ServiceService,
  ) { this.initForm(); }

  ngOnInit(): void {
  }
  initForm() {
    this.menuForm = this.fb.group({
      name: ['', Validators.compose([
        Validators.required, Validators.pattern('^(?! )[A-Za-z -]*(?<! )$'),
     ])],
     subject: ['', Validators.compose([
          Validators.required, Validators.pattern('^(?! )[A-Za-z -]*(?<! )$'),
         ])],
         message: ['', Validators.compose([
            Validators.required, Validators.pattern('^(?! )[A-Za-z -]*(?<! )$'),
          ])],
            email: ['', Validators.compose([
              Validators.required, Validators.pattern('^([0-9a-zA-Z]([-_\\.]*[0-9a-zA-Z]+)*)' +
              '@([0-9a-zA-Z]([-_\\.]*[0-9a-zA-Z]+)*)[\\.]([a-zA-Z]{2,9})+$'),
           ])],
    });
  }
  save() {
    console.log("rerkjerhrkjerkj");
    
    this.isSubmitted = true;
    const controls = this.menuForm.controls;

    // check form
    if (this.menuForm.invalid) {
      Object.keys(controls).forEach(controlName =>
        controls[controlName].markAsTouched()
      );
      this.isSubmitted = false;
      return;
    }
    const formData = new FormData();
    // formData.append('file', this.menuForm.get('fileSource').value);
    console.log("6565666",this.menuForm.value);

    
   

    this.ServiceService.contect_create(this.menuForm.value).subscribe(res => {
      this.isSubmitted = false;
      if (res.status) {
      console.log("YESSSSSSSSSSS");
      window.alert("Your data is inserted");
      this.menuForm.reset();
      } else {
        console.log("NOOOOOOOOO");
        
      }
    }, err => { this.isSubmitted = false; });
  }
  isControlHasError(controlName: string, validationType: string): boolean {
    const control: any = this.menuForm.controls[controlName];
    if (!control) {
      return false;
    }
    const result = control.hasError(validationType) && (control.dirty || control.touched);
    return result;
  }
}
