<header [ngClass]="{ scrolled: scrolled} " (click)="clickBtn()"   
[ngClass]="status ? 'show-menu' : ''">
        <div class="container">
            <div class="row align-items-center">
                    <div class="col-2">
                        <div class="left-logo">
                            <a [routerLink]="['/']">
                                <img src="assets/images/logopng.png" alt="logo-no-img" >
                            </a>
                        </div>
                    </div>
                    <div class="col-10">
                        <div class="menu-box d-flex align-items-center justify-content-end" [ngClass]="status ? 'show-menu' : ''" >
                            <ul class="menu-list" >
                                <li> <a (click)="clickEvent()" [routerLink]="['/']" (click)="clickBtn()"   
                                    [ngClass]="actives ? 'active-links' : ''" > Home </a> </li>
                                    <li> <a  (click)="clickEvent()" [routerLink]="['/our_business']" (click)="clickLink()"  routerLinkActive="active-link"> Our Business Area </a> </li>
                                    <li> <a  (click)="clickEvent()" [routerLink]="['/service']" (click)="clickLink()"  routerLinkActive="active-link">  Services  </a> </li>
                                    <li> <a  (click)="clickEvent()" [routerLink]="['/achievement']" (click)="clickLink()"  routerLinkActive="active-link"> Achievement </a> </li>
                                <li> <a  (click)="clickEvent()" [routerLink]="['/about']" (click)="clickLink()"  routerLinkActive="active-link"> About Us </a> </li>
                                <li> <a  (click)="clickEvent()" [routerLink]="['/career']" (click)="clickLink()"  routerLinkActive="active-link"> Career </a> </li>
                                 <li class="close-li" (click)="clickLink()"  
                                 [ngClass]="status ? 'show-menu' : ''"> <button type="button"> <img src="assets/images/close.svg" > </button> </li>
                                <!--<li> <a href="#"> Expertise </a> </li> -->
                            </ul>
                            <button type="button" class="btn"> 
                                <a href="tel:9586974089">
                                    contact Us
                                    <img src="assets/images/phone-call.svg" alt="phone-img">
                                </a>    
                            </button>
                            <div class="toggle" (click)="clickEvent()"  
                            [ngClass]="status ? 'show-menu' : ''" >
                                <div class="one"></div>
                                <div class="two"></div>
                                <div class="three"></div>
                              </div>
                        </div>
                    </div>
            </div>
        </div>
</header>