<app-banner [data]="'Events'" componentClass="height-box"></app-banner>




<!-- event -->

<div class="event-section">
  <div class="container">
    <div class="row">
      <ng-container *ngFor="let album of albums">
        <div class="col-12 img-col"  *ngIf="album.name == albumName " >
          <div class="silder-main-box">
            <h3>{{ album.name }}</h3>
            <swiper [config]="swiperConfig">
              <ng-template
                *ngFor="let image of album.images"
                data-swiper-autoplay="6000"
                swiperSlide
              >
                <img
                  class="stories-box"
                  [src]="'assets/images/' + album.name + '/' + image"
                />
              </ng-template>
            </swiper>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>
