import { Component, OnInit ,HostListener } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  scrolled: boolean = false;

  status: boolean = false;

  actives: boolean = false;

  clickLink(){
    this.actives = false;
    this.status = false;       
}

clickBtn(){
  this.actives = true;       
}

  clickEvent(){
    this.status = true;       
}

    @HostListener("window:scroll", [])
    onWindowScroll() {
        this.scrolled = window.scrollY > 0;
    }

  constructor() { }

  ngOnInit(): void {
  }

}
