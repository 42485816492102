<app-banner [data]="'Events'" componentClass="height-box"></app-banner>

<!-- event -->

<div class="therapeutic-section">
  <div class="container">
    <div class="row">
      <!-- <div class="col-12">
              <div class="header-box">
                  <h2>gallery</h2>
              </div>
          </div> -->

      <div class="col-12">
        <div class="silder-main-box">
          <!--  -->

          <div class="col-box" *ngFor="let album of albums">
            <div class="service-box" (click)="imageClick(album.name)">
              <div
                class="stories-box Psychiatry"
                [style]="{ backgroundImage: getBackgroundImage(album) }"
              >
                <h4>{{ album.name }}</h4>
              </div>
            </div>
          </div>

          <!--  -->
        </div>
      </div>
    </div>
  </div>
</div>
