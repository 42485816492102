import { Component, Input, OnInit , HostBinding } from '@angular/core';
import { ActivatedRoute, Params} from '@angular/router';

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss']
})
export class BannerComponent implements OnInit {

  @Input('data') data: any;
  @Input() componentClass: string = '';

  // componentClass: any;

  // @HostBinding('class') get HeadingClass() {
  //   return this.componentClass
  // }

  temp:any;

  constructor(private route: ActivatedRoute) { }

  ngOnInit(): void {
    const prodId = this.route.snapshot.data;
    this.temp = Object.values(prodId)

  }
  


}
