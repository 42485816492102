import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PhotoService } from 'src/app/service/photo.service';
import Swiper from 'swiper';

@Component({
  selector: 'app-event',
  templateUrl: './event.component.html',
  styleUrls: ['./event.component.scss']
})
export class EventComponent implements OnInit {

  swiperConfig = {

    slidesPerView: 3,
    spaceBetween: 30,
    slidesPerGroup: 3,
    loop: true,
    autoplay: {
      delay: 2300,
      disableOnInteraction: false
    },
    loopFillGroupWithBlank: true,
    pagination: {
      clickable: true
    },
    class: "mySwiper",
    breakpoints: {
      0: {
        spaceBetween: 10,
        slidesPerView: 1,
      },
      576: {
        spaceBetween: 20,
        slidesPerView: 2,
      },
      992: {
        spaceBetween: 30,
        slidesPerView: 3,
      }
    }
  }

  images: string[] = [];
  albums: any = [];

  constructor(private http: HttpClient, private PhotoService: PhotoService, private router: Router) { }

  ngOnInit() {
    this.image()
  }


  image() {
    this.PhotoService.getAlbums().subscribe(data => {
      this.albums = data.albums;

    });
  }

  getBackgroundImage(album: { name: string, images: Array<string> }) {
    return `url(assets/images/${encodeURIComponent(album.name)}/${album.images[0]})`;
  }

  imageClick(album: any): void {
    console.log('album: ', album);

    // const encodedEventName = encodeURIComponent(album);
    const encodedEventName = decodeURIComponent(album);
    this.router.navigate(['/event_details', encodedEventName]).then(success => {
      if (success) {
        console.log('Navigation successful');
      } else {
        console.log('Navigation failed');
      }
    });
    console.log('album:---2 ', album);
  }



}
