<div class="therapeutic-section">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="header-box">
          <h2>Our Business</h2>
        </div>
      </div>

      <div class="col-12">
        <div class="silder-main-box">
          <div class="col-box">
            <a href="/our_business" class="service-box">
              <div
                class="stories-box Psychiatry"
                style="
                  background-image: url(../../../assets/images/psychiatry.jpg);
                "
              >
                <h4>Psychiatry</h4>
              </div>
            </a>
          </div>

          <div class="col-box">
            <a href="/our_business" class="service-box">
              <div
                class="stories-box Medicines"
                style="background-image: url(../../../assets/images/dental.jpg)"
              >
                <h4>Dental</h4>
              </div>
            </a>
          </div>

          <div class="col-box">
            <a href="/our_business" class="service-box">
              <div
                class="stories-box Medicines"
                style="
                  background-image: url(../../../assets/images/medicines.jpg);
                "
              >
                <h4>Medicines</h4>
              </div>
            </a>
          </div>
        </div>

        <div class="col-12">
          <button type="button" (click)="redirect()" class="btn">See More</button>
        </div>
      </div>
    </div>
  </div>
</div>
