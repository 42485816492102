<div class="therapeutic-section">
  <div class="container">
    <div class="row">
        <div class="col-12">
            <div class="header-box">
                <h2> gallery</h2>
            </div>
        </div>

      <div class="col-12">
        <div class="silder-main-box">
          <div class="col-box">
            <a href="/event_details/Independence%20Day" class="service-box">
              <div
                class="stories-box Psychiatry"
                style="
                  background-image: url(../../../assets/images/1.jpg);
                "
              >
                <h4>Independence Day</h4>
              </div>
            </a>
          </div>

          <div class="col-box">
            <a href="/event_details/Diwali" class="service-box">
              <div
                class="stories-box Medicines"
                style="background-image: url(../../../assets/images/11.jpg)"
              >
                <h4>Diwali</h4>
              </div>
            </a>
          </div>

          <div class="col-box">
            <a href="event_details/Republic%20Day" class="service-box">
              <div
                class="stories-box Medicines"
                style="
                  background-image: url(../../../assets/images/2.jpg);
                "
              >
                <h4>Republic Day</h4>
              </div>
            </a>
          </div>
        </div>

        <div class="col-12">
          <div class="bnt-box">
            <a href="/event" class="btn">View More</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- <div class="gallery-section">
    <div class="container">
        <div class="row">

            <div class="col-12">
                <div class="header-box">
                    <h2> gallery</h2>
                </div>
            </div>

            <div class="col-12">
                <div class="d-flex flex-wrap justify-content-center">
                    <div class="img-div">
                        <figure class="div-img">
                            <img src="assets/images/img-1.jpg" alt="no-img">
                        </figure>
                    </div>
                    <div class="img-div">
                        <figure class="div-img">
                            <img src="assets/images/img-2.jpg" alt="no-img">
                        </figure>
                    </div>
                    <div class="img-div">
                        <figure class="div-img">
                            <img src="assets/images/img-3.jpg" alt="no-img">
                        </figure>
                    </div>
                    <div class="img-div">
                        <figure class="div-img">
                            <img src="assets/images/img-4.jpg" alt="no-img">
                        </figure>
                    </div>
                </div>
            </div>

            <div class="col-12">
                <div class="bnt-box">
                    <a href="/event" class="btn" >View More</a>
                </div>
            </div>

        </div>
    </div>
</div> -->
