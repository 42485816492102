import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SwiperOptions } from 'swiper';

@Component({
  selector: 'app-therapeutic',
  templateUrl: './therapeutic.component.html',
  styleUrls: ['./therapeutic.component.scss']
})
export class TherapeuticComponent implements OnInit {

  public swipeOptions = {
    spaceBetween: 0,
    loop: true,
    speed: 1000,
    centeredSlides: true,
    autoplay: {
      delay: 2500,
      disableOnInteraction: false,
    },
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
  };

  

  constructor(private router: Router) { }

  redirect(){
    this.router.navigate(['/our_business']);
  }


  ngOnInit(): void {
  }

}
