// src/app/services/photo.service.ts
import { Injectable } from '@angular/core';
import { Observable, } from 'rxjs/internal/Observable';
import { HttpClient } from '@angular/common/http';


export interface Album {

  name: string;
  // Add more properties as needed
}
@Injectable({
  providedIn: 'root'
})
export class PhotoService {
  private jsonUrl = 'assets/images/image-list.json';
 
  constructor(private http: HttpClient) { }


  getAlbums(): Observable<{ albums: Album[] }> {
    return this.http.get<{ albums: Album[] }>(this.jsonUrl);
  }
}
